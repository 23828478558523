import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
export default ({ data }) => (
  <Layout>
    <h1>About {data.site.siteMetadata.title} Hakkında</h1>
    <h3> Bodrum’da yaşadığınız mekanlar size özel olsun. </h3>
    <p>
    Yaşadığınız mekanı size özel kılan, sizin tercihleriniz ve hayallerinizdir. Ahşabın sıcaklığını , sağlığını ve şıklığını yaşayın…
    </p>
  </Layout>
)
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`